.combo {
  select {
    min-width: 180px;
    font-family: "Poppins";
    font-weight: 300;
    font-size: 11px;
    line-height: 16px;
    color: #474747;
  }
}
