.preview {
    position: absolute;
    top: 0;
    left: -20px;
    transform: translateX(-100%);
    width: 260px;
    height: 117px;
    // border: 1px solid;
    border-radius: 10px;
    border-top-right-radius: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #FFF;
    
    &::after {
        content: "";
        width: 0;
        border-top: 20px solid transparent;
        border-bottom: 20px solid transparent;
        border-left: 20px solid white;
        height: 0;
        position: absolute;
        top: 0;
        right: 0;
        transform: translateX(100%);
    }
    div {
        button {
            width: 95px;
            height: 23px;
            border-radius: 50px;
            border: 1px solid #383838;
            margin-bottom: 10.75px;
        }
    
        label {
            width: 181.5px;
            height: 23.5px;
            border-radius: 10px;
            border: 0.5px solid #707070;
        }
    }
}