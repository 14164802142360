.button {
  flex-shrink: 0;
  position: relative;
  background: #2f83eb;
  border-radius: 28px;
  width: 120px;
  height: 35px;
  border-radius: 28px;
  font-family: "Poppins";
  font-size: 17px;
  line-height: 26px;
  color: #ffffff;
  border: none;

  @media only screen and (min-width: 960px) {
    /* styles for browsers larger than 960px; */
    width: 120px;
    height: 35px;
  }
  @media (min-width: 1025px) {
    /* big landscape tablets, laptops, and desktops */
    width: 140px;
    height: 40px;
  }
  @media (min-width: 1281px) {
    /* hi-res laptops and desktops */
    width: 160px;
    height: 45px;
  }
  @media only screen and (min-width: 1440px) {
    /* styles for browsers larger than 1440px; */
    width: 180px;
    height: 50px;
  }
  @media only screen and (min-width: 1800px) {
    /* for sumo sized (mac) screens */
    width: 200px;
    height: 55px;
  }

  & > div {
    position: relative;
    z-index: 1;
  }

  a {
    font-family: "Poppins";
    font-weight: 400;
    font-size: 17px;
    line-height: 26px;

    color: #ffffff;
    text-decoration: none;
  }

  &::after {
    content: "";
    position: absolute;
    width: 117px;
    height: 32px;
    background: #2f83eb;
    filter: blur(16px);
    left: 50%;
    top: 11px;
    transform: translateX(-50%);
    border-radius: 28px;
    @media only screen and (min-width: 960px) {
      /* styles for browsers larger than 960px; */
      width: 117px;
      height: 32px;
    }
    @media (min-width: 1025px) {
      /* big landscape tablets, laptops, and desktops */
      width: 137px;
      height: 37px;
    }
    @media (min-width: 1281px) {
      /* hi-res laptops and desktops */
      width: 157px;
      height: 42px;
    }
    @media only screen and (min-width: 1440px) {
      /* styles for browsers larger than 1440px; */
      width: 177px;
      height: 47px;
    }
    @media only screen and (min-width: 1800px) {
      /* for sumo sized (mac) screens */
      width: 197px;
      height: 52px;
    }
  }
}
