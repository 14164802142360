.sidebar {
  width: 300px;
  height: 100vh;
  overflow-y: auto;
  padding: 55px 0;
  @media only screen and (min-width: 960px) {
    /* styles for browsers larger than 960px; */
  }
  @media (min-width: 1025px) {
    /* big landscape tablets, laptops, and desktops */
  }
  @media (min-width: 1281px) {
    /* hi-res laptops and desktops */
  }
  @media only screen and (min-width: 1440px) {
    /* styles for browsers larger than 1440px; */
    padding: 40px 0;
  }
  @media only screen and (min-width: 1800px) {
    /* for sumo sized (mac) screens */
    width: 300px;
  }

  .logo {
    margin-left: 82px;
    width: 41px;
    margin-bottom: 70px;

    @media only screen and (min-width: 960px) {
      /* styles for browsers larger than 960px; */
      width: 40px;
      margin-bottom: 70px;
    }
    @media (min-width: 1025px) {
      /* big landscape tablets, laptops, and desktops */
      width: 48px;
      margin-bottom: 70px;
    }
    @media (min-width: 1281px) {
      /* hi-res laptops and desktops */
      width: 64px;
      margin-bottom: 70px;
    }
    @media only screen and (min-width: 1440px) {
      /* styles for browsers larger than 1440px; */
      width: 72px;
      margin-bottom: 70px;
    }
    @media only screen and (min-width: 1800px) {
      /* for sumo sized (mac) screens */
      width: 82px;
      margin-bottom: 110px;
    }
  }
  .logout {
    margin-left: 82px;
    width: 18px;
    margin-top: 130px;
    @media only screen and (min-width: 960px) {
      /* styles for browsers larger than 960px; */
      width: 18px;
      margin-top: 130px;
    }
    @media (min-width: 1025px) {
      /* big landscape tablets, laptops, and desktops */
      width: 21px;
      margin-top: 130px;
    }
    @media (min-width: 1281px) {
      /* hi-res laptops and desktops */
      width: 28px;
      margin-top: 130px;
    }
    @media only screen and (min-width: 1440px) {
      /* styles for browsers larger than 1440px; */
      width: 32px;
      margin-top: 130px;
    }
    @media only screen and (min-width: 1800px) {
      /* for sumo sized (mac) screens */
      width: 36px;
      margin-top: 200px;
    }
  }
  .version {
    font-family: "Poppins";
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: #313131;
    margin-left: 82px;
    margin-top: 22px;
  }

  ul {
    list-style: none;
    padding: 0;

    .link {
      cursor: pointer;
      display: flex;
      align-items: center;
      height: 60px;
      margin-bottom: 20px;
      @media only screen and (min-width: 1800px) {
        /* for sumo sized (mac) screens */
        height: 77px;
      }

      .active {
        border-radius: 0 50px 50px 0;
        height: 100%;
        background: #c0d9f9;
      }
      .icon-item {
        position: relative;
        width: 200px;
        display: flex;
        text-align: right;

        .icon-container {
          flex-shrink: 0;
          position: absolute;
          right: 30px;
          top: 50%;
          transform: translateY(-50%);
          width: 18px;
          @media only screen and (min-width: 960px) {
            /* styles for browsers larger than 960px; */
            width: 18px;
          }
          @media (min-width: 1025px) {
            /* big landscape tablets, laptops, and desktops */
            width: 21px;
          }
          @media (min-width: 1281px) {
            /* hi-res laptops and desktops */
            width: 28px;
          }
          @media only screen and (min-width: 1440px) {
            /* styles for browsers larger than 1440px; */
            width: 32px;
          }
          @media only screen and (min-width: 1800px) {
            /* for sumo sized (mac) screens */
            width: 36px;
          }

          img {
            width: 100%;
          }
        }
      }

      span {
        width: 100%;
        padding-left: 20px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
}
