.topbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px 70px;

  .search {
    position: relative;

    input {
      width: 437px;
      height: 56px;
      background: #f8f6f7;
      border-radius: 28px;
      font-family: "Poppins";
      font-weight: 200;
      font-size: 18px;
      line-height: 24px;
      padding-left: 75px;
      padding-right: 12px;
      outline: none;
      border: none;
    }

    img {
      position: absolute;
      left: 30px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 66px;
    height: 66px;
    border-radius: 50%;
    background: #d9d9d9;
    border: 5px solid #ffffff;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.25);

    img {
      width: 60px;
      height: 60px;
      border-radius: 50%;
    }
  }
}
