.user-edit {
  background: #ffffff;

  .toolbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0px;

    & > div:first-child {
      .title {
        font-family: "Poppins";
        font-weight: 700;
        font-size: 29.6306px;
        line-height: 44px;
        margin-bottom: 5px;
      }

      .description {
        font-family: "Poppins";
        font-size: 16px;
        line-height: 24px;
        color: #313131;
        margin-bottom: 10px;
      }
    }
    & > div:last-child {
      width: 208px;
      margin-top: auto;
    }
  }
  .saveButton {
    width: 60px;
    height: 23px;
    background: #c7c7c7;
    border-radius: 27px;
    border: none;
    font-family: "Poppins";
    font-weight: 500;
    font-size: 10px;
    line-height: 15px;
    color: #ffffff;
  }
}
table {
  
  .active {
    
    background-color: #2F83EB;
    td{
      color: #fff;
    }
    td>button{
      border: 1px solid #fff !important;
      background-color: #2F83EB !important;
    }
  }
}
