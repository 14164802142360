.FTP-Edit {
  background: #ffffff;

  .toolbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0px;

    & > div:first-child {
      .title {
        font-family: "Poppins";
        font-weight: 700;
        font-size: 29.6306px;
        line-height: 44px;
        margin-bottom: 5px;
      }

      .description {
        font-family: "Poppins";
        font-size: 16px;
        line-height: 24px;
        color: #313131;
        margin-bottom: 10px;
      }
    }
  }

  .data-table {
    margin-bottom: 50px;
  }

  .my-table {
    .processBtn {
      width: 100px;
      height: 22px;
      border: 1px solid #2f83eb;
      background-color: #ffffff;
      border-radius: 10px;

      font-family: "Poppins";
      font-weight: 500;
      font-size: 10px;
      line-height: 15px;
      color: #2f83eb;
    }
    .processBtn.active{
      background-color: #2f83eb;
      color: #fff;
    }
  }
}
